// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Frame from './components/Frame/Frame';
import StreamerConsole from './components/StreamerConsole/StreamerConsole';

const Placeholder = () => <div></div>; // Placeholder component for incomplete routes

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Frame />} />
                <Route path="/streamer-console" element={<StreamerConsole />} />
                <Route path="/browse-games" element={<Placeholder />} />
                <Route path="/services" element={<Placeholder />} />
                <Route path="/examples" element={<Placeholder />} />
                <Route path="/contact" element={<Placeholder />} />
            </Routes>
        </Router>
    );
};

export default App;
