import { memo } from 'react';

import resets from '../../../resets.css';
import classes from './CompanyMilestoneMetrics.module.css';

export const CompanyMilestoneMetrics = memo(function CompanyMilestoneMetrics(props = {}) {
  return (
    <div className={`${resets.storybrainResets} ${classes.root}`}>
      <div className={classes['rectangle1']}>
        <div className={classes['unnamed']}>Affiliated Streamers</div>
        <div className={classes['unnamed2']}>Co Streamers</div>
        <div className={classes['unnamed3']}>Cooperative Streamers Union</div>
        <div className={classes['unnamed4']}>Released Gameplays</div>
      </div>
      <div className={classes['companyMetrics']}></div>
    </div>
  );
});
