import { memo } from 'react';

import resets from '../../../resets.css';
import classes from './AboutUs.module.css';

export const AboutUs = memo(function AboutUs(props = {}) {
  return (
    <div className={`${resets.storybrainResets} ${classes.root}`}>
      <div className={classes['aboutUs']}></div>
      
      <div className={classes['aboutUs2']}>About us</div>
      
      <div className={classes['intro']}>
        Symposia Studio was established in 2023, focusing on the development of Live interactive games on the Tiktok
        LIVE streaming platform. We aim to use the cloud game service to create a new Tiktok interaction model and
        explore the infinite opportunities of live streaming content. We see each liveroom as a community of co-creative
        possibilities, and we are the Symposia that creates opportunities for everyone to freely participate in.
      </div>
{/*       
      <div className={classes['rectangle']}><div className={classes['iMAGE']}></div></div>
      
      <div className={classes['rectangle2']}><div className={classes['iMAGE2']}></div></div>
      
      <div className={classes['rectangle3']}><div className={classes['iMAGE3']}></div></div> */}
    </div>
  );
});

export default AboutUs;
