import { memo } from 'react';
import { Link } from 'react-router-dom';

import resets from '../../../resets.css';
import classes from './TopNavBar.module.css';

export const TopNavBar = memo(function TopNavBar(props = {}) {
  return (
    <div className={`${resets.storybrainResets} ${classes.root}`}>
      <div className={classes.topNavBar}>
      <div className={classes.symposiaStudio}> Symposia Studio</div>
      
      <Link to="/streamer-console" className={classes.streamerConsole} id="streamerConsoleContainer">
        Streamer Console
      </Link>
      <div className={classes['browseGamesDropdown']}>
        <Link to="/browse-games" className={classes['browseGamesLink']}>
          Browse games
      </Link>
        <div className={classes['dropdown-content']}>
            <p>Falls Animals</p>
            <p>Slot Machine</p>
            {/* You can add more content here */}
        </div>
      </div>
      <Link to="/services" className={classes.services}>
        Services
      </Link>
      <Link to="/examples" className={classes.examples}>
        Examples
      </Link>
      <Link to="/contact" className={classes.contact}>
        Contact
      </Link>
    </div>
    </div>
  );
});

export default TopNavBar;
