import { memo } from 'react';

import resets from '../../../resets.css';
import classes from './Footline.module.css';

export const Footline = memo(function Footline(props = {}) {
  return (
    <div className={`${resets.storybrainResets} ${classes.root}`}>
      <div className={classes['columns']}>
        <div className={classes['column']}>
          <div className={classes['subTitle']}>Company</div>
          <ul className={classes['subcontentList']}>
            <div className={classes['subContent']}>Our team</div>
            <div className={classes['subContent']}>Terms of Service</div>
          </ul>
        </div>
        <div className={classes['column']}>
          <div className={classes['subTitle']}>Product</div>
          <ul className={classes['subcontentList']}>
            <div className={classes['subContent']}>Product list</div>
            <div className={classes['subContent']}>Service Content</div>
            <div className={classes['subContent']}>Customer Examples</div>
          </ul>
        </div>
        <div className={classes['column']}>
          <div className={classes['subTitle']}>Help</div>
          <ul className={classes['subcontentList']}>
            <div className={classes['subContent']}>FAQ</div>
            <div className={classes['subContent']}>Tutorial</div>
            <div className={classes['subContent']}>Promotion and reward</div>
            <div className={classes['subContent']}>Contact us</div>
          </ul>
        </div>
      </div>
    </div>
  );
});
