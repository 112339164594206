import { memo } from 'react';

import resets from '../../resets.css';
import { AboutUs } from './AboutUs/AboutUs';
import { Banner } from './Banner/Banner';
import { CompanyMilestoneMetrics } from './CompanyMilestoneMetrics/CompanyMilestoneMetrics';
import { Footline } from './Footline/Footline';
import classes from './Frame.module.css';
import { TopNavBar } from './TopNavBar/TopNavBar';
import { WhatWeProvideSec } from './WhatWeProvideSec/WhatWeProvideSec';

const Frame = memo(function Frame(props = {}) {
  return (
    <div className={`${resets.storybrainResets} ${classes.root}`}>
      <TopNavBar />
      <Banner />
      <WhatWeProvideSec />
      <AboutUs />
      <CompanyMilestoneMetrics />
      <Footline />
    </div>
  );
});

export default Frame;
