import React from 'react';
import classes from './Banner.module.css';

const BackgroundMedia = ({ source, type, ...props }) => {
  if (type === 'video') {
    return (
      <video className={classes.media} autoPlay muted loop {...props}>
        <source src={source} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    );
  } else {
    // Assume 'image' as the default type
    return (
      <img src={source} alt="Background Media" className={classes.media} {...props} />
    );
  }
};

export default BackgroundMedia;
