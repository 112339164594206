import { memo, useState } from 'react';

import BackgroundMedia from './BackgroundMedia.js';
import classes from './Banner.module.css';

export const Banner = memo(function Banner(props = {}) {

  const assets = [
  { type: 'image', source: '/assets/BannerImg.JPEG' },
  { type: 'image', source: '/assets/BannerImg2.png' },
  { type: 'video', source: '/assets/BannerVideo.mp4' }
];

  const [currentAsset, setCurrentAsset] = useState(assets && assets.length > 0 ? 0 : -1); // Start with the first image
  const onNextClick = () => {
    setCurrentAsset((currentAsset + 1) % assets.length);
  };

  const onPrevClick = () => {
    setCurrentAsset((currentAsset - 1 + assets.length) % assets.length);
  };

  return (
    <div className={classes.root}>
      <button className={classes.arrow} onClick={onPrevClick}>&lt;</button>
      <div className={classes.banner} style={{backgroundImage: `url(${assets[currentAsset]})`}}>
        <div className={classes.backgroundImg}>
          <BackgroundMedia source={assets[currentAsset].source} type={assets[currentAsset].type} className={`${classes.media} ${classes.backgroundMedia}`} />
          <BackgroundMedia source={assets[currentAsset].source} type={assets[currentAsset].type}/>
        </div>
      </div>
      <button className={classes.arrow} onClick={onNextClick}>&gt;</button>
    </div>
  );
});

export default Banner;
