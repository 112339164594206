import { memo } from 'react';

import resets from '../../../resets.css';
import classes from './WhatWeProvideSec.module.css';

export const WhatWeProvideSec = memo(function WhatWeProvideSec(props = {}) {
  return (
    <div className={`${resets.storybrainResets} ${classes.root}`}>
      <div className={classes.titleContainer}>
        <div className={classes.title}>What We Provide</div>
      </div>
      <div className={classes["grid-container"]}>
        <div className={classes["row"]}>
          <div className={classes["column"]}>
            <div className={classes['subTitle']}>Customized live interacting game</div>
            {<div className={classes['subContent']}>
            We are the pioneer team of professional engineers in the bullet screen interactive industry. Share your
            requirements with us, and we will deliver the product. We provide support for multiple engines, including Unity,
            Cocos, LayaBox, and Unreal Engine.
            </div>}
          </div>
          <div className={classes["column"]}>
            <div className={classes['subTitle']}>Innovated live interacting games</div>
            {<div className={classes['subContent']}>
              Emphasizing technological innovation, self-planning, self-development, and self-promotion, we have successfully
              launched a variety of small-scale games across multiple platforms. Some notable examples include
            </div>}
          </div>
          <div className={classes["column"]}>
            <div className={classes['subTitle']}>live game developers community</div>
            {<div className={classes['subContent']}>
              Independent developers who are unsure of how to enter the industry? Game studios looking to transition and enter
              the market? Join us in collaborative development, where we can expedite product launch in as little as 7 days
              and offer profit-sharing with the highest ratio of up to 80%.
            </div>}
          </div>
        </div>
        <div className={classes["row"]}>
          <div className={classes["column"]}>
            <div className={classes['subTitle']}>Cultivation Program for Streamers</div>
            {<div className={classes['subContent']}>
            No prior live streaming experience required, both part-time and full-time positions are available. A
            revenue-sharing model for live streaming earnings is provided, helping you quickly become an interactive game
            streamer.
            </div>}
          </div>
          <div className={classes["column"]}>
            <div className={classes['subTitle']}>Collaborative Promotion of Interactive Gameplay</div>
            {<div className={classes['subContent']}>
              The interactive barrage gameplay is saturated, without live streamers? By collaborating with promotional
              channels and mobilizing multiple streamers, it will give a significant boost of 100 to your company's live
              gameplay, propelling it to new heights.
            </div>}
          </div>
          <div className={classes["column"]}>
            <div className={classes['subTitle']}>Media Company Franchise Opportunities</div>
            {<div className={classes['subContent']}>
              You have the venue, resources, and connections, and you want to start an interactive live streaming company? Our
              Interactive Cloud Live Streaming model provides a 1:1 replication, with official experience and resource
              support, enabling a quick establishment and launch.
            </div>}
          </div>
        </div>
      </div>
    </div>
  );
});

export default WhatWeProvideSec;

