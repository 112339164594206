import React, { useState, useEffect } from 'react';
import styles from './StreamerConsole.module.css';


const StreamerConsole = () => {
  const [playerId, setPlayerId] = useState('');
  const [danmu, setDanmu] = useState('');
  const [giftName, setGiftName] = useState('');
  const [ids, setIds] = useState([]);
  const [ws, setWs] = useState(null);

  const [tiktokUsername, setTiktokUsername] = useState('');
  const [tikTokConnected, setTikTokConnected] = useState(false);
  const [tiktokLiveRoomUsername, setTiktokLiveRoomUsername] = useState('');

  



  useEffect(() => {
    // Create a new WebSocket connection
    //let websocket = new WebSocket('ws://54.159.171.208:8080/streamer-console');
    //let websocket = new WebSocket('ws://localhost:8080/streamer-console');
    let websocket = new WebSocket('wss://symposiastudio.com/ws-streamer-console');
    setWs(websocket);

    websocket.onopen = () => {
      console.log('WebSocket connection opened'); 
      var data = { "action": "getPlayers" };
      websocket.send(JSON.stringify(data));
    };

    websocket.onmessage = (event) => {
      var data;
      try {
        data = JSON.parse(event.data);
      } catch (error) {
        console.error('Error parsing JSON:', error);
        return;
      }

      // show current connected tiktok live room username
      if (data.action === 'getTiktokLiveRoomUsername') {
        setTiktokLiveRoomUsername(data.tiktokUsername);
      }

      // Handle getPlayers
      if (data.action === 'getPlayers') {
        var ids = data.playerId.split(',');
        setIds(ids);
      } 


      // Handle player join and leave
      else if (data.action === 'addPlayer' || data.action === 'addPlayerSC' || data.action === 'deletePlayer') {
        if (data.action === 'addPlayer' || data.action === 'addPlayerSC') {
          console.log('Player connected:', data.playerId);
          setIds(prevIds => [...prevIds, data.playerId]);
          console.log('currentIds after joining', ids);
        } else if (data.action === 'deletePlayer') {
          console.log('Player disconnected:', data.playerId);
          setIds(prevIds => prevIds.filter(id => id !== data.playerId));
          console.log('currentIds after leaving', ids);
        }
      }

      // // Handle TikTok live events
      // // Add TikTok user as a player when they join the live
      // else if (data.action === 'addPlayer') {
      //     setIds(prevIds => [...prevIds, data.playerId]);
      // }

      // // Send a danmu message when a TikTok user comments on the live
      // else if (data.action === 'sendDanmu') {
      //     setDanmu(data.danmu);
      //     sendDanmu();
      // }

      // // Handle gift action from tiktok audience
      // else if (data.action === 'gift') {
      //     gift();
      // }

      // // Handle like action from tiktok audience
      // else if (data.action === 'like') {
      //     like();
      // }

      else if (data.message) {
        console.log(data.message);
    }
    };

    websocket.onerror = (error) => {
      console.log('WebSocket encountered an error:', error);
    };
    
    websocket.onclose = (event) => {
      console.log('WebSocket connection closed:', event.code);
    };

    return () => {
      websocket.close();
    };
  }, []);

  //=====================================================================================================
  // Handle TikTok connection: press the button to connect/disconnect
  const handleTikTokConnection = () => {
    if (tikTokConnected) {
      const data = {
        action: 'toggleTikTokConnection',
        tiktokUsername: tiktokUsername
      };
    
      // Check that the WebSocket connection is not null or undefined
      if (ws && ws.readyState === WebSocket.OPEN) {
        ws.send(JSON.stringify(data));
      } else {
        console.error('WebSocket connection is not open:', ws);
      }
  
      // Clear TikTok username
      setTiktokUsername('');
  
    } else if (tiktokUsername !== '') {
      const data = {
        action: 'toggleTikTokConnection',
        tikTokUsername: tiktokUsername
      };
    
      // Check that the WebSocket connection is not null or undefined
      if (ws && ws.readyState === WebSocket.OPEN) {
        ws.send(JSON.stringify(data));
      } else {
        console.error('WebSocket connection is not open:', ws);
      }
    }
  
    // Switch the connection status
    setTikTokConnected(!tikTokConnected);
  };
  
  //=====================================================================================================
  //define functions of streamer console
    const addPlayer = () => {
        const data = JSON.stringify({
          action: 'addPlayerSC',
          playerId: playerId,
        });
        if (ws && ws.readyState === WebSocket.OPEN) {
          ws.send(data);
        }
    }

    const like = () => {
        const data = JSON.stringify({
          action: 'like',
          playerId: playerId,
        });
        if (ws && ws.readyState === WebSocket.OPEN) {
          ws.send(data);
        }
      }

    const sendDanmu = () => {
        const data = JSON.stringify({
          action: 'sendDanmu',
          playerId: playerId,
          danmu: danmu,
        });
        if (ws && ws.readyState === WebSocket.OPEN) {
          ws.send(data);
        }
      }

    const gift = () => {
        const data = JSON.stringify({
          action: 'gift',
          playerId: playerId,
          giftName: giftName,
        });
        if (ws && ws.readyState === WebSocket.OPEN) {
          ws.send(data);
      }
    }

    const addBot = () => {
        var botPlayerId = 'Bot' + Math.floor(Math.random() * 1000); // Generate a random ID for bot
        const data = JSON.stringify({
          action: 'addBot',
          playerId: botPlayerId,
        });
        if (ws && ws.readyState === WebSocket.OPEN) {
          ws.send(data);
      }
    }
    
    const leaveGame = () => {
          const data = JSON.stringify({
            action: 'deletePlayer',
            playerId: playerId,
          });
          if (ws && ws.readyState === WebSocket.OPEN) {
            ws.send(data);
        }
    }


  const goToHomePage = () => {
    window.location.href = '/';
  };



  return (
    <div className={styles['streamer-console-wrapper']}>
      <div className={styles['flex-row']}>
      <input type="text" id="tiktokUsername" placeholder="TikTok Username" value={tiktokUsername} onChange={(e) => setTiktokUsername(e.target.value)} />
      <button className={tikTokConnected ? styles.highlighted : styles.greyedOut} onClick={handleTikTokConnection}>
        {tikTokConnected ? 'Disconnect TikTok' : 'Connect TikTok'}
      </button>
      </div>

      <div>
        <h3>Connected TikTok Liveroom Username: {tiktokLiveRoomUsername}</h3>
      </div>

      <div className={styles['admin-console']}>
      <h3>Admin Console</h3>

        <div className={styles['flex-row']}>
          <input type="text" id="playerId" placeholder="Player ID" value={playerId} onChange={(e) => setPlayerId(e.target.value)} />
          <button onClick={addPlayer}>join</button>
          <button onClick={leaveGame}>leave</button>
        </div>

        <div className={styles['flex-row']}>
          <input type="text" id="danmu" placeholder="danmu" value={danmu} onChange={(e) => setDanmu(e.target.value)} />
          <button className="button" onClick={sendDanmu}>Send</button>
        </div>

        <div className={styles['flex-row']}></div>
          <select value={giftName} onChange={(e) => setGiftName(e.target.value)}>
          <option value="">Select a gift...</option>
          <option value="Rose">Rose</option>
          <option value="Star">Star</option>
          <option value="Finger Heart">Finger Heart</option>
          </select>
          <button className={`button ${styles['giftButton']}`} onClick={gift}>
            <i className="fas fa-gift"></i>
          </button>

          <button className={`button ${styles['thumbsUpButton']}`} onClick={like}>
            <i className="fas fa-thumbs-up"></i>
          </button>
          
          <button className={`button ${styles['addBotButton']}`} onClick={addBot}>
            <i className="fas fa-robot"></i>
          </button>
        </div>
      


      <button className={styles['home-button']} onClick={goToHomePage}><i className="fas fa-home"></i></button>

      <div id={styles['ids']}>
      <h3>Current Players</h3>
      {ids.map(id => (
        <p key={id}>{id}</p>
      ))}
      </div>
    </div>
  );



};

export default StreamerConsole;
